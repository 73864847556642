import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import icon from '../../../Assets/images/icons/icon.png';
import './style.scss';

const onClickItem = (event, item) => {
  if (item.disable) {
    event.preventDefault();
  }
};

const SideChildItem = ({ item, ishaveIcon, isExpand }) => {
  const { t } = useTranslation();
  return (
    <div className={`${item.disable ? 'disable' : ''} ${item.wrapperClass || ''}`}>
      <NavLink to={item.href} onClick={evt => onClickItem(evt, item)}>
        <div className="pull-left ">
          <i className={item.icon} />
          <span className="right-nav-text">{item.isNoTranslation ? item.label : t(item.label) || item.label}</span>
        </div>
        <div className={classNames('icon-hover', { invisiable: !isExpand })}>
          <img src={icon} style={{ width: item.widthRightIcon || 20, height: 'auto' }} alt="" />
        </div>
        <div className="clearfix" />
      </NavLink>
    </div>
  );
};
const CollapseNavi = ({ item, isExpand, backLink, href, isExpandChild, onHandleSetMaximize, isMaximizeOne, index }) => {
  const { t } = useTranslation();
  const [isExpandCollapse, setExpand] = useState(isExpandChild || false);
  useEffect(() => {
    return setExpand(isExpandChild);
  }, [isExpandChild]);

  return (
    <li key={item.mainTilte} className="item-nav">
      {item && item.childs.length > 0 ? (
        <a
          className={classNames(
            `right-nav-text nav-main cursor ${item.childs && item.childs.length ? 'remove-active-nav-bar' : ''}`,
            { invisiable: !isExpand }
          )}
          onClick={() => {
            // (isMaximizeOne ? onHandleSetMaximize(index) : setExpand(!isExpandCollapse))
            onHandleSetMaximize(index);
            setExpand(!isExpandCollapse);
          }}
          aria-expanded={!isExpandCollapse}
          // to={`${backLink || href || '#'}`}
          to={`${backLink || href || '#'}`}
        >
          <div className="pull-left">
            <span className="right-nav-text">{t(item.mainTilte) || item.mainTilte}</span>
          </div>
          <div className="pull-right pull-right-persist">
            {item && item.childs.length > 0 && <i className="ti-plus" />}
          </div>
          <div className="clearfix" />
        </a>
      ) : (
        <NavLink
          className={classNames(
            `right-nav-text nav-main ${item.childs && item.childs.length ? 'remove-active-nav-bar' : ''}`,
            { invisiable: !isExpand }
          )}
          onClick={() => (isMaximizeOne ? onHandleSetMaximize(index) : setExpand(!isExpandCollapse))}
          aria-expanded={!isExpandCollapse}
          to={`${backLink || href || '#'}`}
        >
          <div className="pull-left">
            <span className="right-nav-text nav-main-item">{t(item.mainTilte) || item.mainTilte}</span>
          </div>
          <div className="pull-right pull-right-persist">
            {item && item.childs.length > 0 && <i className="ti-plus" />}
          </div>
          <div className="clearfix" />
        </NavLink>
      )}
      <div className={classNames({ 'display-none': isExpandCollapse })}>
        {item.childs.map((child, index) => {
          return <SideChildItem key={index} item={child} isExpand={isExpand} ishaveIcon />;
        })}
      </div>
    </li>
  );
};
const SideBar = props => {
  const { items, isExpand, isSupportCheckRoute, keyRoute, isExpandChild, ...rest } = props;
  const isMaximizeOne = true;
  const { t } = useTranslation();
  const [indexMaximize, setMaximize] = useState(null);
  const [isFirstSetExpand, setIsFirstSetExpand] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const onHandleSetMaximize = index => {
    if (index === indexMaximize) {
      return setMaximize(null);
    }
    return setMaximize(index);
  };
  useEffect(() => {
    const pathName = window.location.pathname;
    if (items && items.length) {
      items.forEach((el, index) => {
        const { childs } = el || {};
        if (
          childs &&
          childs.length &&
          childs.find(val => val.href === pathName) &&
          (!isFirstSetExpand || currentPath !== pathName) &&
          !el.isNoExpand
        ) {
          setCurrentPath(pathName);
          setIsFirstSetExpand(true);
          setMaximize(index);
        }
      });
    }
  }, [currentPath, isFirstSetExpand, items]);
  return (
    <div className={`side-menu-fixed ${isExpand ? 'expanded-sidebar' : ''}`}>
      <div className="scrollbar side-menu-bg">
        <ul className="nav navbar-nav side-menu" id="sidebarnav">
          {items.map((item, index) => {
            if (item.isHaveExpand) {
              let isCheckExpandChild = false;
              if (isMaximizeOne) {
                isCheckExpandChild = indexMaximize !== index;
              }
              if (!isMaximizeOne) {
                isCheckExpandChild = isExpandChild || item.isExpandChild;
              }
              if (isSupportCheckRoute) {
                isCheckExpandChild = !(keyRoute === item.key);
              }
              return (
                <CollapseNavi
                  key={index}
                  href={item.href}
                  item={item}
                  isExpandChild={isCheckExpandChild}
                  isExpand={isExpand}
                  isMaximizeOne={isMaximizeOne}
                  onHandleSetMaximize={onHandleSetMaximize}
                  index={index}
                  {...rest}
                />
              );
            }
            return [
              <li
                key={index}
                className={classNames('mt-10 mb-10 text-muted pl-4 font-medium menu-title', {
                  'display-none': !isExpand,
                })}
              >
                {t(item.mainTilte)}
              </li>,
              item.childs.map((child, childIndex) => {
                return <SideChildItem key={childIndex} item={child} ishaveIcon isExpand={isExpand} />;
              }),
            ];
          })}
        </ul>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  isExpand: PropTypes.bool,
  isMaximizeOne: PropTypes.bool,
};
SideBar.defaultProps = {
  items: [],
  isExpand: false,
  isMaximizeOne: false,
};

CollapseNavi.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
  backLink: PropTypes.string,
  href: PropTypes.string,
};
CollapseNavi.defaultProps = {
  item: {},
  isExpand: false,
  backLink: '',
  href: '',
};

SideChildItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
  ishaveIcon: PropTypes.bool,
};
SideChildItem.defaultProps = {
  item: {},
  isExpand: false,
  ishaveIcon: false,
};

export default SideBar;
