import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import JobList from './JobList';
import { ModalAccept, GenericInput } from '../../../components/common';
import { makeGetPermissionsJobsManagement, makeGetCcpTime } from '../../App/selectors';
import {
  checkPermissionCreateJobSchedule,
  checkPermissionGetJobSchedule,
  checkPermissionModifyJobSchedule,
  checkPermissionProcessJobSchedule,
  checkPermissionModifyJob,
  checkPermissionProcessJob,
  checkPermissionReprocessJob,
  checkPermissionGetJobStats,
  checkPermissionModifyJobType,
  checkPermissionRefreshJobSchedule,
} from '../CheckPermission';
import {
  getJobScheduleByDate,
  createJobScheduleByDate,
  modifyJobSchedule,
  processJobSchedule,
  retryJobSchedule,
  updateStatusJobSchedule,
  recreateJobScheduleByDate,
  resetSubsequentJobsFromErrorJob,
} from '../actions';
import { makeJobSchedule, makeGetScheduleList, makeGetDailyList } from '../selectors';
import { getDateOnJob, setDateOnJob, getUserId } from '../../../localStorages';
import { getCcpDateTimeConfig } from '../../App/actions';

let scheduleSelect = {};

const listInputFields = [
  {
    label: 'label.frequency',
    name: 'scheduleFrequency',
  },
  {
    label: 'label.name',
    name: 'name',
  },
  {
    label: 'label.description',
    name: 'description',
    type: 'textarea',
  },
  {
    label: 'label.scheduleTime',
    name: 'jobScheduleTime',
    type: 'time',
    wrapperClass: 'col-md-3',
    isSupportDefaultTime: true,
  },
  {
    label: 'label.exceptionStartTime',
    name: 'exceptionStartTime',
    type: 'time',
    wrapperClass: 'col-md-3',
    isSupportDefaultTime: true,
  },
  {
    label: 'label.exceptionEndTime',
    name: 'exceptionEndTime',
    type: 'time',
    wrapperClass: 'col-md-3',
    isSupportDefaultTime: true,
  },
];

const canPause = jsd => jsd.status !== 'SUSPENDED' && jsd.status !== 'ERROR';
const canProcess = jsd => jsd.status === 'PENDING';
const checkReProcess = listJobs => {
  if (!listJobs || !listJobs.length) return false;
  return listJobs.filter(val => val.status === 'ERROR').length > 0;
};

const formatScheduleDate = scheduleDate => {
  return scheduleDate ? moment(scheduleDate).format('YYYY-MM-DD') : null;
};

class JobSchedule extends Component {
  state = {
    selectedDate: getDateOnJob() ? moment(getDateOnJob()).toDate() : moment().toDate(),
    isRefreshDay: false,
    isProcessByDate: false,
    isReprocess: false,
    modalAcceptType: null,
    itemSelect: null,
  };

  componentDidMount() {
    const { ccpTime, getCcpDateTimeConfig } = this.props;
    this.doGetJobScheduleByDate();
    if (!ccpTime) {
      getCcpDateTimeConfig('');
    }
  }

  doGetJobScheduleByDate = () => {
    const { getJobScheduleByDate } = this.props;
    getJobScheduleByDate({
      date: getDateOnJob() || moment().format('YYYY-MM-DD'),
      scheduleFrequency: scheduleSelect.scheduleFrequency || null,
      scheduleName: scheduleSelect.name || null,
    });
  };

  onChangeDate = date => {
    const { getJobScheduleByDate } = this.props;
    setDateOnJob(moment(date).format('YYYY-MM-DD'));
    this.setState({
      selectedDate: date,
      isProcessByDate: false,
    });
    getJobScheduleByDate({
      date: moment(date).format('YYYY-MM-DD'),
      scheduleFrequency: scheduleSelect.scheduleFrequency || null,
      scheduleName: scheduleSelect.name || null,
    });
  };

  onChangeOneDate = days => {
    const { getJobScheduleByDate } = this.props;
    const { selectedDate } = this.state;
    const newDate = moment(selectedDate).add(days, 'days');
    this.setState({
      selectedDate: newDate,
    });
    getJobScheduleByDate({
      date: newDate.format('YYYY-MM-DD'),
      scheduleFrequency: scheduleSelect.scheduleFrequency || null,
      scheduleName: scheduleSelect.name || null,
    });
  };

  onRefreshJobSchedule = () => {
    const { getJobScheduleByDate } = this.props;
    const { isRefreshDay, selectedDate } = this.state;
    this.setState({ isRefreshDay: !isRefreshDay });
    getJobScheduleByDate({
      date: moment(selectedDate).format('YYYY-MM-DD'),
      scheduleFrequency: scheduleSelect.scheduleFrequency || null,
      scheduleName: scheduleSelect.name || null,
    });
  };

  onCreateJobSchedule = () => {
    const { createJobScheduleByDate } = this.props;
    const { selectedDate } = this.state;
    createJobScheduleByDate({
      scheduleDate: formatScheduleDate(selectedDate),
      userId: getUserId() || null,
      scheduleFrequency: scheduleSelect.scheduleFrequency || null,
      scheduleName: scheduleSelect.name || null,
    });
  };

  onPauseEntire = () => {
    const { jobSchedule } = this.props;
    this.callUpdateStatus({ id: jobSchedule.id, status: 'SUSPENDED' });
  };

  onProcessEntire = () => {
    const { jobSchedule } = this.props;
    this.callProcess(
      { scheduleDate: formatScheduleDate(jobSchedule.scheduleDate), id: jobSchedule.id || null },
      true
    );
  };

  onProcessJob = job => {
    const { jobSchedule } = this.props;
    this.callProcess({
      id: jobSchedule.id,
      scheduleDate: formatScheduleDate(jobSchedule.scheduleDate),
      jobScheduleList: [{ index: job.index }],
    });
  };

  onRetryJob = job => {
    const { jobSchedule } = this.props;
    this.callRetry({
      id: jobSchedule.id,
      scheduleDate: formatScheduleDate(jobSchedule.scheduleDate),
      jobScheduleList: [{ index: job.index, errorReason: job.reason || null }],
    });
  };

  callRetry = data => {
    const { retryJobSchedule } = this.props;
    retryJobSchedule(data);
  };

  callRetryAll = () => {
    const { retryJobSchedule, jobSchedule, getJobScheduleByDate } = this.props;
    retryJobSchedule(
      { scheduleDate: formatScheduleDate(jobSchedule.scheduleDate), id: jobSchedule.id || null },
      () => {
        getJobScheduleByDate({
          date: formatScheduleDate(jobSchedule.scheduleDate),
          scheduleFrequency: scheduleSelect.scheduleFrequency || null,
          scheduleName: scheduleSelect.name || null,
        });
      }
    );
  };

  callProcess = (data, isAll) => {
    const { processJobSchedule } = this.props;
    processJobSchedule(data, ({ success }) => {
      if (success) this.setState({ isProcessByDate: isAll, isReprocess: false });
      if (!success) this.setState({ isReprocess: true, isProcessByDate: false });
    });
  };

  callUpdateStatus = data => {
    const { updateStatusJobSchedule } = this.props;
    updateStatusJobSchedule(data, ({ success }) => {
      this.setState({ isProcessByDate: true });
    });
  };

  onModifyJob = job => {
    const { jobSchedule, modifyJobSchedule } = this.props;
    modifyJobSchedule({
      id: jobSchedule.id,
      userId: jobSchedule.userId || getUserId() || null,
      jobScheduleList: [
        {
          ...job,
        },
      ],
    });
  };

  onSortJobs = jobs => {
    const { jobSchedule, modifyJobSchedule } = this.props;
    const queuedJobs = [];
    jobs.forEach((job, idx) => {
      queuedJobs.push({
        index: job.index,
        nextJobId: jobs[idx + 1] ? jobs[idx + 1].jobId : '',
        predJobId: idx > 0 ? jobs[idx - 1].jobId : '',
      });
    });

    modifyJobSchedule({
      id: jobSchedule.id,
      userId: jobSchedule.userId || getUserId() || null,
      jobScheduleList: queuedJobs,
    });
  };

  onRecreate = () => {
    const { jobSchedule, recreateJobScheduleByDate } = this.props;
    const { jobScheduleList, userId, scheduleDate, id } = jobSchedule;
    const newjobScheduleList = jobScheduleList.map(job => ({
      index: job.index,
      name: job.name,
      type: job.type,
      scheduleType: job.scheduleType,
    }));
    recreateJobScheduleByDate(
      {
        id,
        jobScheduleList: newjobScheduleList,
        userId,
        scheduleDate: formatScheduleDate(scheduleDate),
        scheduleFrequency: scheduleSelect.scheduleFrequency || null,
        scheduleName: scheduleSelect.name || null,
      },
      ({ success }) => {
        if (success) {
          this.doGetJobScheduleByDate();
        }
      }
    );
  };

  onResetJobSchedule = () => {
    const { resetSubsequentJobsFromErrorJob, jobSchedule } = this.props;
    resetSubsequentJobsFromErrorJob(jobSchedule.id, ({ success }) => {
      if (success) this.doGetJobScheduleByDate();
    });
  };

  onHandleModalSubmit = () => {
    const { modalAcceptType, itemSelect } = this.state;
    if (modalAcceptType === 'process') {
      this.onProcessEntire();
    }
    if (modalAcceptType === 'reProcess') {
      this.callRetryAll();
    }
    if (modalAcceptType === 'processItem') {
      this.onProcessJob(itemSelect);
    }
    if (modalAcceptType === 'retryItem') {
      this.onRetryJob(itemSelect);
    }
    this.setState({ modalAcceptType: null, itemSelect: null });
  };

  onHandleOpenModal = ({ type, itemSelect }) => {
    this.setState({ modalAcceptType: type, itemSelect: itemSelect || null });
  };

  render() {
    const {
      jobSchedule,
      permissionsJob,
      t,
      dailyList,
      scheduleList,
      ccpTime,
      match: { params } = { params: {} },
    } = this.props;
    const jobScheduleList = jobSchedule ? jobSchedule.jobScheduleList : [];
    const { selectedDate, isProcessByDate, modalAcceptType } = this.state;
    const lockedProcess = moment(ccpTime).diff(selectedDate, 'days') < 0;
    let modeCreateJobSchedule = 0;
    let modeGetJobSchedule = 0;
    let modeModifyJobSchedule = 0;
    let modeProcessJobSchedule = 0;
    let modeModifyJob = 0;
    let modeProcessJob = 0;
    let modeReprocessJob = 0;
    let modeGetJobStats = 0;
    let modeModifyJobType = 0;
    let modeRefreshJobSchedule = 0;
    if (permissionsJob && permissionsJob.jobsModulePermissions) {
      const listPermission = permissionsJob.jobsModulePermissions;
      modeCreateJobSchedule = checkPermissionCreateJobSchedule({ listPermission });
      modeGetJobSchedule = checkPermissionGetJobSchedule({ listPermission });
      modeModifyJobSchedule = checkPermissionModifyJobSchedule({ listPermission });
      modeProcessJobSchedule = checkPermissionProcessJobSchedule({ listPermission });
      modeModifyJob = checkPermissionModifyJob({ listPermission });
      modeProcessJob = checkPermissionProcessJob({ listPermission });
      modeReprocessJob = checkPermissionReprocessJob({ listPermission });
      modeGetJobStats = checkPermissionGetJobStats({ listPermission });
      modeModifyJobType = checkPermissionModifyJobType({ listPermission });
      modeRefreshJobSchedule = checkPermissionRefreshJobSchedule({ listPermission });
    }

    if (modeGetJobSchedule === 0) return '';
    let listJobConfig = [];
    if (dailyList && dailyList.length) listJobConfig = [...dailyList];
    if (scheduleList && scheduleList.length) listJobConfig = [...listJobConfig, ...scheduleList];
    if (listJobConfig && listJobConfig.length) scheduleSelect = listJobConfig.find(val => val.id === params.id);
    if (!scheduleSelect) return null;
    const isExistJobError = checkReProcess(
      jobSchedule && jobSchedule.jobScheduleList ? jobSchedule.jobScheduleList : []
    );

    return (
      <div>
        <div className="page-title">
          <div className="col-md-12 mb-30">
            <div className="card cardt card-statistics h-100">
              <div className="card-body">
                <div className="d-block d-sm-flex justify-content-between col-md-12 p-0">
                  <div className="d-block">
                    <h5 className="pt-2 pb-2">
                      {t('label.jobSchedule')}
                      {jobSchedule && (
                        <span className="job-schedule-status">
                          <i
                            className={`fa fa-circle job-status job-status-${
                              jobSchedule.status && !isExistJobError ? jobSchedule.status.toLocaleLowerCase() : 'error'
                            }`}
                            title={`Status: ${jobSchedule.status}`}
                          />
                          &nbsp; &nbsp;
                          {modeRefreshJobSchedule !== 0 && (
                            <button
                              type="button"
                              title="Refresh jobs"
                              className="btn-no-css"
                              disabled={modeRefreshJobSchedule === 1}
                              onClick={() => this.onRefreshJobSchedule()}
                            >
                              <i className="fa fa-refresh" />
                            </button>
                          )}
                        </span>
                      )}
                    </h5>
                  </div>
                  <div className="row mr-3">
                    <div className="box clearfix sm-mb-10">
                      <div className="card-body datepicker-form">
                        <div className="mb-0">
                          <DatePicker
                            selected={selectedDate}
                            onChange={this.onChangeDate}
                            minDate={moment().subtract(3, 'months')}
                            maxDate={moment().add(1, 'months')}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    {!jobSchedule && modeCreateJobSchedule === 2 && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            <button type="button" className="btn btn-info" onClick={this.onCreateJobSchedule}>
                              {t('label.createJobSchedule')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {jobSchedule && jobSchedule.status === 'PENDING' && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            <button type="button" className="btn btn-info" onClick={this.onRecreate}>
                              {t('label.recreate')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {jobSchedule && modeModifyJobSchedule === 2 && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.onPauseEntire}
                              disabled={isProcessByDate || !canPause(jobSchedule) || modeModifyJobSchedule === 1}
                            >
                              {t('label.pause')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {jobSchedule && modeModifyJobSchedule === 2 && modeProcessJobSchedule !== 0 && (
                      <div className="box clearfix sm-mb-10">
                        <div className="card-body datepicker-form">
                          <div className="form-group mb-0">
                            {!isExistJobError ? (
                              <button
                                type="button"
                                className="btn btn-success"
                                // onClick={this.onProcessEntire}
                                onClick={() => this.onHandleOpenModal({ type: 'process' })}
                                disabled={
                                  isProcessByDate ||
                                  !canProcess(jobSchedule) ||
                                  lockedProcess ||
                                  modeModifyJobSchedule === 1 ||
                                  modeProcessJobSchedule === 1
                                }
                              >
                                {t('label.process')}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => this.onHandleOpenModal({ type: 'reProcess' })}
                                disabled={
                                  // isProcessByDate ||
                                  lockedProcess || modeModifyJobSchedule === 1 || modeProcessJobSchedule === 1
                                }
                              >
                                {t('label.reProcess')}
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn btn-success ml-2"
                              onClick={this.onResetJobSchedule}
                              disabled={
                                !jobSchedule?.resetAllowed ||
                                modeModifyJobSchedule === 1 ||
                                modeProcessJobSchedule === 1
                              }
                            >
                              {t('label.resetJobSchedule')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="col-md-12 row m-2">
                {listInputFields.map(val => (
                  <GenericInput
                    {...val}
                    value={scheduleSelect ? scheduleSelect[val.name] || null : null}
                    readOnly
                    disabled
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          {jobSchedule && jobSchedule.jobScheduleList && (
            <JobList
              jobs={jobScheduleList}
              onSortJobs={this.onSortJobs}
              onProcessJob={job => this.onHandleOpenModal({ type: 'processItem', itemSelect: job })}
              onRetryJob={job => this.onHandleOpenModal({ type: 'retryItem', itemSelect: job })}
              onModifyJob={this.onModifyJob}
              lockedProcess={lockedProcess}
              modeModifyJob={modeModifyJob}
              modeProcessJob={modeProcessJob}
              modeReprocessJob={modeReprocessJob}
              modeGetJobStats={modeGetJobStats}
              modeModifyJobType={modeModifyJobType}
            />
          )}
          {!jobSchedule && (
            <div className="text-center text-bold">
              {t('label.noJobScheduleOn')}
              &nbsp;
              <strong>{moment(selectedDate).format('MM/DD/YYYY')}</strong>
            </div>
          )}
        </div>
        <ModalAccept
          onAcceptModal={this.onHandleModalSubmit}
          isOpen={modalAcceptType}
          message={t('common:message.processJobMessage')}
          onToggle={() => {
            this.setState({
              modalAcceptType: null,
            });
          }}
        />
      </div>
    );
  }
}

JobSchedule.propTypes = {
  getJobScheduleByDate: PropTypes.func.isRequired,
  createJobScheduleByDate: PropTypes.func.isRequired,
  modifyJobSchedule: PropTypes.func.isRequired,
  processJobSchedule: PropTypes.func.isRequired,
  retryJobSchedule: PropTypes.func.isRequired,
  updateStatusJobSchedule: PropTypes.func.isRequired,
  jobSchedule: PropTypes.objectOf(PropTypes.any),
  permissionsJob: PropTypes.objectOf(PropTypes.any),
  ccpTime: PropTypes.string,
  getCcpDateTimeConfig: PropTypes.func.isRequired,
};

JobSchedule.defaultProps = {
  permissionsJob: {},
  jobSchedule: {},
  ccpTime: null,
};

const mapStateToProps = createStructuredSelector({
  jobSchedule: makeJobSchedule() || {},
  permissionsJob: makeGetPermissionsJobsManagement() || {},
  scheduleList: makeGetScheduleList() || {},
  dailyList: makeGetDailyList() || {},
  ccpTime: makeGetCcpTime() || '',
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getJobScheduleByDate,
    createJobScheduleByDate,
    modifyJobSchedule,
    processJobSchedule,
    retryJobSchedule,
    updateStatusJobSchedule,
    recreateJobScheduleByDate,
    resetSubsequentJobsFromErrorJob,
    getCcpDateTimeConfig,
  })(JobSchedule)
);
